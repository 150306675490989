import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { BiDownload } from "react-icons/bi";
import { AiFillInfoCircle, AiOutlineEye } from "react-icons/ai";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  generateIndividualQRCode,
  generateQRCodes,
  getTemplate,
  showLoader,
} from "../../redux/actions/templateActions";
import { useHistory } from "react-router-dom";
import InfoToolTip from "../../components/InfoToolTip";
import FileUploader from "../../components/FileUploader";
import {
  addProceedingZero,
  csvToJsonPapa,
  csvToString,
  validateCSV,
} from "../../utils/csvParser";
import CSVErrorsModal from "../../components/modals/CSVErrorsModal";
import download from "downloadjs";
import { toast } from "react-toastify";
import { checkPermission } from "../../redux/actions/permissionActions";
import {
  QR_GENERATE_BULK,
  QR_GENERATE_INDIVIDUAL,
} from "../../utils/permissionList";
import {
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED_ACTION,
} from "../../utils/errorMessages";
import { getQRFieldValidations } from "../../redux/actions/qrSpecificationActions";
import { getTransactionTypes } from "../../redux/actions/transactionTypeActions";
import { receiveMarketData } from "../../redux/actions/profileActions";
import SelectQrImageSize from "../../components/modals/SelectQrImageSize";
import { getFormatString } from "../../utils/common";
import { getLanguages } from "../../redux/actions/languageActions";

const regexForAlphabatical = /[A-Za-z]/;
const regexForNumeric = /[0-9]/;
const regexForSpecialCharacters = /[^A-Za-z0-9]/;
const regexForANS = /^[a-zA-Z0-9!"#$%&'()*+,-^_.\/?>=<;:@`{}|~\s]+$/;

let singleSchema = yup.object().shape({
  trxCode: yup.string().required("Transaction code is required"),
  creditPartyIdentifier: yup
    .string()
    .min(1, "Credit party identifier is required"),
  creditPartyName: yup.string().min(1, "Credit party name is required"),
  additionalQRParams: yup
    .array()
    .of(
      yup.object().nullable().shape({
        mpaFieldName: yup.string(),
        mpaFieldValue: yup.string(),
        format: yup.string(),
        lengthMin: yup.number(),
        lengthMax: yup.number(),
        lengthType: yup.string(),
        fieldLabel: yup.string(),
      })
    )
    .default([]),
});

const bulkUploadSchema = yup.object().shape({});

const FillParameters = ({
  selectedTemplate,
  generateQRCodes,
  generateIndividualQRCode,
  getQRFieldValidations,
  checkPermissions,
  isLoading,
  getTemplate,
  additionalParams = [],
  isSuperAdmin,
  currentUserMarket,
  marketOptions = [],
  qrImageSize = 100,
  trxTypeOptions,
  getTransactionTypeList,
  receiveMarketData,
  getLanguageList,
}) => {
  const [filters] = useState({
    isActiveOnly: true,
    isDropdown: true,
  });
  const [selectedOption, setSelectedOption] = useState("single");
  const [selectedOutput, setSelectedOutput] = useState("pdf");
  const [jsonData, setJsonData] = useState([]);
  const [csvErrors, setCSVErrors] = useState([]);
  const [validFile, setValidFile] = useState(false);
  const [showCSVErrorModal, setShowCSVErrorModal] = useState(false);
  const [validations, setValidations] = useState(null);
  const [helpText, setHelpText] = useState({
    trxCodeHelpText: "The transaction code",
    cpnHelpText: "The credit party name, this can be organization name",
    cpiHelpText:
      "The credit party identifier, this can be till number or shortcode",
  });
  const [permissions, setPermissions] = useState({
    hasBulkPermission: false,
    hasIndividualPermission: false,
  });
  const [maxRecords, setMaxRecords] = useState(200);
  const [qrParams, setQRParams] = useState([]);
  const [marketSelected, setMarketSelected] = useState(false);
  const [templateWithValidation, setTemplateWithValidation] = useState(null);
  const [selectedMarket, setSelectedMarket] = useState("");
  const [disableGenerateButton, setDisableGenerateButton] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState({
    show: false,
    content: "",
  });
  const [showSizePicker, setShowSizePicker] = useState(false);
  const [imageData, setImageData] = useState({});
  const [fileNameText, setFileNameText] = useState("");
  const [allRequiredFields, setAllRequiredFields] = useState(additionalParams);

  const history = useHistory();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      trxCode: null,
      creditPartyIdentifier: null,
      creditPartyName: null,
      additionalQRParams: [],
    },
    resolver: yupResolver(singleSchema),
    mode: "all",
    reValidateMode: "onChange",
  });

  const {
    register: registerSchema,
    handleSubmit: handleSubmitBulk,
    reset: resetBulk,
    formState: { errors: bulkErrors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(bulkUploadSchema),
    mode: "onChange",
  });

  useEffect(() => {
    async function getTemplateAsync() {
      isLoading(true);
      // check for permissions
      const hasBulkPermission = await checkPermissions([QR_GENERATE_BULK]);
      const hasIndividualPermission = await checkPermissions([
        QR_GENERATE_INDIVIDUAL,
      ]);

      setPermissions({ hasBulkPermission, hasIndividualPermission });

      // if user can only crate bulk
      if (hasBulkPermission && !hasIndividualPermission) {
        setSelectedOption("bulk");
      } else if (hasIndividualPermission && !hasBulkPermission) {
        setSelectedOption("single");
      }

      // get the template details 
      // API call
      const result = await getTemplate(selectedTemplate.id);
      const template = result.data;
      setHelpText({
        trxCodeHelpText: template.trxCodeHelpText,
        cpnHelpText: template.cpnHelpText,
        cpiHelpText: template.cpiHelpText,
      });
      getLanguages(template);

      setValidations(template.validations);
      setTemplateWithValidation(template);

      if (!template.isGeneral) {
        setMarketSelected(true);
        await setAdditionalFieldsAndValidations(template, template.market);
      } else {
        if (template.isGeneral && !isSuperAdmin) {
          setMarketSelected(true);
          await setAdditionalFieldsAndValidations(template, currentUserMarket);
        } else {
          setMarketSelected(false);
        }
      }
      isLoading(false);
    }
    getTemplateAsync();
    async function getLanguages(template) {
      const result = await getLanguageList({ isDropdown: true, isActiveOnly: true });
      const lang = result?.languages?.find(x => x.shortCode3 === template.language)?.name
      if(lang) {
        const formattedCpnHelpText = <div> {template.cpnHelpText}  <br/><br/> Preferred language: {lang} </div>
        setHelpText({
          trxCodeHelpText: template.trxCodeHelpText,
          cpnHelpText: formattedCpnHelpText,
          cpiHelpText: template.cpiHelpText})
      }
    }
  }, [
    getTemplate,
    selectedTemplate,
    checkPermissions,
    isSuperAdmin,
    isLoading,
  ]);

  useEffect(() => {
    getTransactionTypeList(filters);
    receiveMarketData();
  }, []);

  const setAdditionalFieldsAndValidations = async (template, market) => {
    isLoading(true);
    setSelectedMarket(market);
    if (market === "" || market === null) {
      isLoading(false);
      setMarketSelected(false);
      return;
    }
    singleSchema = yup.object().shape({
      trxCode: yup.string().required("Required"),
      market: yup.string(),
      creditPartyIdentifier: yup.string().min(1, "Required"),
      creditPartyName: yup.string().min(1, "Required"),
      additionalQRParams: yup
        .array()
        .of(
          yup
            .object()
            .nullable()
            .shape({
              mpaFieldName: yup.string(),
              mpaFieldValue: yup
                .string()
                .when(["lengthMin", "lengthMax"], (lengthMin, lengthMax, schema) => {
                  if (lengthMin == null && lengthMax == null) {
                    return schema; // No validation applied
                  } 
                  let updatedSchema = schema;
                  if (lengthMin != null && lengthMin != 0) {
                    updatedSchema = updatedSchema.min(lengthMin, `Minimum length for this field is ${lengthMin}`);
                  }
                  if (lengthMax != null && lengthMin != 0) {
                    updatedSchema = updatedSchema.max(lengthMax, `Maximum length for this field is ${lengthMax}`);
                  }
                  return updatedSchema;
                }
                  
                  
                )
                .required("Required")
                .test({
                  name: "max",
                  exclusive: false,
                  params: {},
                  test: function (value, { createError, path }) {
                    if (value) {
                      try {
                        const limit =
                          this.parent.lengthType === "fixed"
                            ? `{${this.parent.lengthMin}}`
                            : `{${this.parent.lengthMin},${this.parent.lengthMax}}`;
                        const limitDisplay =
                          this.parent.lengthType === "fixed"
                            ? `${this.parent.lengthMin}`
                            : `${this.parent.lengthMin}-${this.parent.lengthMax}`;

                        let regexString = "^";
                        let formatLabel = "";

                        switch (this.parent.format) {
                          case "ANS":
                            regexString = `^.${limit}$`;
                            formatLabel = "alphanumeric special";
                            break;
                          case "AN":
                            regexString = `^[A-Za-z0-9]${limit}$`;
                            formatLabel = "alphanumeric";
                            break;
                          case "N":
                            regexString = `^[0-9]${limit}$`;
                            formatLabel = "numeric";
                            break;
                          case "A":
                            regexString = `^[a-zA-Z]${limit}$`;
                            formatLabel = "alphabetic";
                            break;
                          case "SP":
                            regexString = `^[^A-Za-z0-9]${limit}$`;
                            formatLabel = "special";
                            break;
                          default:
                          // console.log('thus ->', this.parent)
                          //   toast.error(
                          //     "Unrecognized format " + this.parent.format
                          //   );
                            break;
                        }
                        const regEx = new RegExp(regexString);
                        if (!regEx.test(value)) {
                          return createError({
                            message: `Expected ${formatLabel} characters with length ${limitDisplay}`,
                            path,
                          });
                        }
                        return true;
                      } catch (e) {
                        console.log(e);
                      }
                    } else {
                      return true;
                    }
                  },
                }),
              fieldLabel: yup.string(),
              format: yup.string(),
              lengthMin: yup.number(),
              lengthMax: yup.number(),
              lengthType: yup.string(),
            })
        )
        .default([]),
    });

    const results = await getQRFieldValidations(market);
    const fields = results.data;
    // CPI, CPN and TrxCode validations should always be there
    // since checking the length for more than 2
    if (results && fields && fields.length > 2) {
      try {
        setShowErrorMessage({ show: false, content: "" });
        setDisableGenerateButton(false);

        const mpaRequiredKeys = [
          "creditpartyname", "creditpartyidentifier", "trxcode"
        ]
        // remove the mpa required fields to reorder them
        const additionalFields = fields.filter(x => !mpaRequiredKeys.includes(x.parentFieldKey.toLowerCase()));
        const mainParameters = fields.filter(x => mpaRequiredKeys.includes(x.parentFieldKey.toLowerCase()));
        if (mainParameters.length < mpaRequiredKeys.length - 1) {
          setShowErrorMessage({ show: true, content: "Invalid QR Specification" });
          setDisableGenerateButton(true);
          isLoading(false);
          return;
        }
        const cpnValidation = mainParameters.filter(x => x.parentFieldKey.toLowerCase() === mpaRequiredKeys[0])[0];
        const cpiValidation = mainParameters.filter(x => x.parentFieldKey.toLowerCase() === mpaRequiredKeys[1])[0];
        // const trxCodeValidation = mainParameters.filter(x => x.parentFieldKey.toLowerCase() === mpaRequiredKeys[2])[0];

        // compare and merge template and additional fields
        // additional fields added from template
        if (template.additionalFields) {
          template.additionalFields.forEach(x => {
            const index = additionalFields.findIndex(k => k.parentFieldKey === x.mpaKey);
            if (index === -1) {
              // add the missing value
              const formatString = getFormatString(x.validation.format.hasNumbers, x.validation.format.hasAlphabets, x.validation.format.hasSpecialChars);
              const lengthType = x.validation.length === x.validation.minLength ? "fixed" : "ranged";
              additionalFields.push({ ...x, fieldName: x.mpaFieldName, parentFieldName: x.mpaFieldName, parentFieldKey: x.mpaKey, validation: { format: formatString, lengthMax: x.validation.length, lengthMin: x.validation.minLength, lengthType } });
            }
          });
        }
        setAllRequiredFields([...mainParameters, ...additionalFields]);
        setQRParams(additionalFields);

        // extended validation
        // CPI
        singleSchema = singleSchema.test("cpiValidation", null, (obj) => {
          if (
            obj.creditPartyIdentifier === null ||
            obj.creditPartyIdentifier === "" ||
            obj.creditPartyIdentifier === undefined
          ) {
            return false;
          }
          const cpiValidations = cpiValidation.validation;
          // if it is a dynamic template ignore the mismatch validations
          // validation mismatch between QR template and the QR Specification
          if (!template.isDynamic) {
            // length mismatch check
            if (cpiValidations.lengthType === "ranged") {
              if (
                template.validations.cpiMinLength < cpiValidations.lengthMin
              ) {
                return new yup.ValidationError(
                  "Credit party identifier min length mismatch with QR Specification",
                  null,
                  "creditPartyIdentifier"
                );
              }

              if (template.validations.cpiLength > cpiValidations.lengthMax) {
                return new yup.ValidationError(
                  "Credit party identifier max length mismatch with QR Specification",
                  null,
                  "creditPartyIdentifier"
                );
              }
            } else {
              if (
                template.validations.cpiMinLength !==
                cpiValidations.lengthMin ||
                template.validations.cpiLength !== cpiValidations.lengthMax
              ) {
                return new yup.ValidationError(
                  "Credit party identifier length mismatch with QR Specification (QR template length is " +
                  template.validations.cpiMinLength +
                  "-" +
                  template.validations.cpiLength +
                  " expected " +
                  cpiValidations.lengthMax +
                  " in the QR specification)",
                  null,
                  "creditPartyIdentifier"
                );
              }
            }

            //  check for format mismatches
            switch (cpiValidations.format) {
              case "ANS":
                // any restrictin wil work
                break;
              case "AN":
                if (template.validations.cpiFormat.hasSpecialChars) {
                  return new yup.ValidationError(
                    "Credit party identifier format mismatch with QR Specification (QR template allow (S), QR Specification does not allow (s))",
                    null,
                    "creditPartyIdentifier"
                  );
                }
                break;
              case "N":
                if (template.validations.cpiFormat.hasAlphabets) {
                  return new yup.ValidationError(
                    "Credit party identifier format mismatch with QR Specification (QR template allow (A), QR Specification does not allow (A))",
                    null,
                    "creditPartyIdentifier"
                  );
                }

                if (template.validations.cpiFormat.hasSpecialChars) {
                  return new yup.ValidationError(
                    "Credit party identifier format mismatch with QR Specification (QR template allow (S), QR Specification does not allow (A))",
                    null,
                    "creditPartyIdentifier"
                  );
                }
                break;
              case "A":
                if (template.validations.cpiFormat.hasNumbers) {
                  return new yup.ValidationError(
                    "Credit party identifier format mismatch with QR Specification (QR template allow (N), QR Specification does not allow (N))",
                    null,
                    "creditPartyIdentifier"
                  );
                }

                if (template.validations.cpiFormat.hasSpecialChars) {
                  return new yup.ValidationError(
                    "Credit party identifier format mismatch with QR Specification (QR template allow (S), QR Specification does not allow (S))",
                    null,
                    "creditPartyIdentifier"
                  );
                }
                break;
              case "SP":
                if (template.validations.cpiFormat.hasNumbers) {
                  return new yup.ValidationError(
                    "Credit party identifier format mismatch with QR Specification (QR template allow (N), QR Specification does not allow (N))",
                    null,
                    "creditPartyIdentifier"
                  );
                }

                if (template.validations.cpiFormat.hasAlphabets) {
                  return new yup.ValidationError(
                    "Credit party identifier format mismatch with QR Specification (QR template allow (A), QR Specification does not allow (A))",
                    null,
                    "creditPartyIdentifier"
                  );
                }
                break;
              default:
                toast.error("Unrecognized format " + cpiValidation.format);
                break;
            }
            // END OF QR Specification validation --->
          }
          // if input length is less than expected minimum length
          if (obj.creditPartyIdentifier.length < cpiValidations.lengthMin) {
            return new yup.ValidationError(
              "Minimum length for Credit party identifier is " +
              cpiValidations.lengthMin,
              null,
              "creditPartyIdentifier"
            );
          }
          // if input length is greater than expected maximum length
          if (obj.creditPartyIdentifier.length > cpiValidations.lengthMax) {
            return new yup.ValidationError(
              "Maximum length for Credit party identifier is " +
              cpiValidations.lengthMax,
              null,
              "creditPartyIdentifier"
            );
          }

          // if input length is less than expected minimum length
          if (
            obj.creditPartyIdentifier.length < template.validations.cpiMinLength
          ) {
            return new yup.ValidationError(
              "Minimum length for Credit party identifier is " +
              template.validations.cpiMinLength,
              null,
              "creditPartyIdentifier"
            );
          }
          // if input length is greater than expected maximum length
          if (
            obj.creditPartyIdentifier.length > template.validations.cpiLength
          ) {
            return new yup.ValidationError(
              "Maximum length for Credit party identifier is " +
              template.validations.cpiLength,
              null,
              "creditPartyIdentifier"
            );
          }

          // if input has number characters and are not expected
          if (
            !template.validations.cpiFormat.hasNumbers &&
            obj.creditPartyIdentifier.match(regexForNumeric)
          ) {
            return new yup.ValidationError(
              "Credit party identifier cannot have numerical values",
              null,
              "creditPartyIdentifier"
            );
          }
          // if input has alphabet characters and are not expected
          if (
            !template.validations.cpiFormat.hasAlphabets &&
            obj.creditPartyIdentifier.match(regexForAlphabatical)
          ) {
            return new yup.ValidationError(
              "Credit party identifier cannot have alphabatical values",
              null,
              "creditPartyIdentifier"
            );
          }
          // if input has special characters
          if (obj.creditPartyIdentifier.match(regexForSpecialCharacters)) {
            return new yup.ValidationError(
              "Credit party identifier cannot have special characters",
              null,
              "creditPartyIdentifier"
            );
          }

          return true;
        });

        // CPN
        singleSchema = singleSchema.test("cpnValidation", null, (obj) => {
          if (
            obj.creditPartyName === null ||
            obj.creditPartyName === "" ||
            obj.creditPartyName === undefined
          ) {
            return false;
          }
          const cpnValidations = cpnValidation.validation;

          // if it is a dynamic template ignore the mismatch validations
          // validation mismatch between QR template and the QR Specification
          if (!template.isDynamic) {
            // length mismatch check
            if (cpnValidations.lengthType === "ranged") {
              if (
                template.validations.cpnMinLength < cpnValidations.lengthMin
              ) {
                return new yup.ValidationError(
                  "Credit party name min length mismatch with QR Specification",
                  null,
                  "creditPartyName"
                );
              }

              if (template.validations.cpnLength > cpnValidations.lengthMax) {
                return new yup.ValidationError(
                  "Credit party name max length mismatch with QR Specification",
                  null,
                  "creditPartyName"
                );
              }
            } else {
              if (
                template.validations.cpnMinLength !==
                cpnValidations.lengthMin ||
                template.validations.cpnLength !== cpnValidations.lengthMax
              ) {
                return new yup.ValidationError(
                  "Credit party name length mismatch with QR Specification (QR template length is " +
                  template.validations.cpnMinLength +
                  "-" +
                  template.validations.cpnLength +
                  " expected " +
                  cpnValidations.lengthMax +
                  " in the QR specification)",
                  null,
                  "creditPartyIdentifier"
                );
              }
            }

            //  check for format mismatches
            switch (cpnValidations.format) {
              case "ANS":
                if (template.validations.cpnFormat.hasAnyChars) {
                  return new yup.ValidationError(
                    "Credit party name format mismatch with QR Specification (QR template allow (S), QR Specification does not allow (S))",
                    null,
                    "creditPartyName"
                  );
                }
                break;
              case "AN":
                if (template.validations.cpnFormat.hasSpecialChars) {
                  return new yup.ValidationError(
                    "Credit party name format mismatch with QR Specification (QR template allow (Special chars), QR Specification does not allow (Special chars))",
                    null,
                    "creditPartyName"
                  );
                }
                break;
              case "N":
                if (template.validations.cpnFormat.hasAlphabets) {
                  return new yup.ValidationError(
                    "Credit party name format mismatch with QR Specification (QR template allow (A), QR Specification does not allow (A))",
                    null,
                    "creditPartyName"
                  );
                }

                if (template.validations.cpnFormat.hasSpecialChars) {
                  return new yup.ValidationError(
                    "Credit party name format mismatch with QR Specification (QR template allow (Special chars), QR Specification does not allow (Special chars))",
                    null,
                    "creditPartyName"
                  );
                }
                break;
              case "A":
                if (template.validations.cpnFormat.hasNumbers) {
                  return new yup.ValidationError(
                    "Credit party name format mismatch with QR Specification (QR template allow (N), QR Specification does not allow (N))",
                    null,
                    "creditPartyName"
                  );
                }

                if (template.validations.cpnFormat.hasSpecialChars) {
                  return new yup.ValidationError(
                    "Credit party name format mismatch with QR Specification (QR template allow (Special Chars), QR Specification does not allow (Special Chars))",
                    null,
                    "creditPartyName"
                  );
                }
                break;
              case "SP":
                if (template.validations.cpnFormat.hasNumbers) {
                  return new yup.ValidationError(
                    "Credit party name format mismatch with QR Specification (QR template allow (N), QR Specification does not allow (N))",
                    null,
                    "creditPartyName"
                  );
                }

                if (template.validations.cpnFormat.hasAlphabets) {
                  return new yup.ValidationError(
                    "Credit party name format mismatch with QR Specification (QR template allow (A), QR Specification does not allow (A))",
                    null,
                    "creditPartyName"
                  );
                }
                break;
                case "S":
                  // any restrictin wil work
                  break;
              default:
                  toast.error("Unrecognized format " + cpiValidation.format);
                break;
            }
          }

          // if input length is less than expected minimum length
          if (obj.creditPartyName.length < cpnValidations.lengthMin) {
            return new yup.ValidationError(
              "Minimum length for Credit party identifier is " +
              cpnValidations.lengthMin,
              null,
              "creditPartyName"
            );
          }
          // if input length is greater than expected maximum length
          if (obj.creditPartyName.length > cpnValidations.lengthMax) {
            return new yup.ValidationError(
              "Maximum length for Credit party identifier is " +
              cpnValidations.lengthMax,
              null,
              "creditPartyName"
            );
          }

          // if input length is less than expected minimum length
          if (obj.creditPartyName.length < template.validations.cpnMinLength) {
            return new yup.ValidationError(
              "Minimum length for credit party name is " +
              template.validations.cpnMinLength,
              null,
              "creditPartyName"
            );
          }
          // if input length is greater than expected maximum length
          if (obj.creditPartyName.length > template.validations.cpnLength) {
            return new yup.ValidationError(
              "Maximum length for credit party name is " +
              template.validations.cpnLength,
              null,
              "creditPartyName"
            );
          }

          // if input has number characters and are not expected
          if (
            !template.validations.cpnFormat.hasAnyChars &&
            !obj.creditPartyName.match(regexForANS) 
          ) {
            return new yup.ValidationError(
              "Credit party name cannot have other language characters",
              null,
              "creditPartyName"
            );
          }
          if (
            !template.validations.cpnFormat.hasNumbers &&
            obj.creditPartyName.match(regexForNumeric)
          ) {
            return new yup.ValidationError(
              "Credit party name cannot have numerical values",
              null,
              "creditPartyName"
            );
          }
          // if input has alphabet characters and are not expected
          if (
            !template.validations.cpnFormat.hasAlphabets &&
            obj.creditPartyName.match(regexForAlphabatical)
          ) {
            return new yup.ValidationError(
              "Credit party name cannot have alphabatical values",
              null,
              "creditPartyName"
            );
          }
          // if input has special characters and are not expected
          if (
            !template.validations.cpnFormat.hasSpecialChars &&
            obj.creditPartyName.match(regexForSpecialCharacters)
          ) {
            return new yup.ValidationError(
              "Credit party name cannot have special characters",
              null,
              "creditPartyName"
            );
          }

          return true;
        });
        reset({
          market,
          trxCode: null,
          creditPartyIdentifier: null,
          creditPartyName: null,
          additionalQRParams: [],
        });
        setMarketSelected(true);
        isLoading(false);
      } catch (e) {
        setShowErrorMessage({
          show: true,
          content: "Invalid QR Specification",
        });
        setDisableGenerateButton(true);
        isLoading(false);
      }
    } else {
      setShowErrorMessage({ show: true, content: "Invalid QR Specification" });
      setDisableGenerateButton(true);
    }
  };

  useEffect(() => {
    reset({
      trxCode: null,
      creditPartyIdentifier: null,
      creditPartyName: null,
      additionalQRParams: [],
    });
    resetBulk();
    setValidFile(false);
  }, [selectedOption, reset, resetBulk]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Select a market to download the sample CSV
    </Tooltip>
  );

  const onSubmit = (data) => {
    setImageData(data);
    let downloadAsPDF = selectedOutput === "pdf";
    let downloadAsQrIMG = selectedOutput === "qrImage";

    if (downloadAsQrIMG) {
      setShowSizePicker(true);
    } else {
      submitGenerateRequest(data, downloadAsPDF, downloadAsQrIMG);
    }
  };

  const submitGenerateRequest = (data, downloadAsPDF, downloadAsQrIMG) => {
    if (selectedTemplate.isGeneral) {
      if (isSuperAdmin) {
        downloadFinal(data, downloadAsPDF, downloadAsQrIMG, selectedMarket);
      } else {
        // if not an admin but is general template
        downloadFinal(data, downloadAsPDF, downloadAsQrIMG, currentUserMarket);
      }
    } else {
      downloadFinal(
        data,
        downloadAsPDF,
        downloadAsQrIMG,
        selectedTemplate.market
      );
    }
  };

  const downloadFinal = (data, downloadAsPDF, downloadAsQrIMG, market) => {
    isLoading(true);
    let payload = null;
    if (selectedOption === "single") {
      payload = { ...data };
      generateIndividualQRCode(
        payload,
        selectedTemplate.id,
        downloadAsPDF,
        downloadAsQrIMG,
        qrImageSize,
        market
      )
        .then((result) => {
          if (result) {
            if (downloadAsPDF) {
              const content = result.headers["content-type"];
              if (!payload.creditPartyName) {
                download(
                  result.data,
                  payload.creditPartyIdentifier + ".pdf",
                  content
                );
              } else {
                download(
                  result.data,
                  payload.creditPartyIdentifier +
                  "-" +
                  payload.creditPartyName +
                  ".pdf",
                  content
                );
              }
            } else {
              const content = result.headers["content-type"];
              if (!payload.creditPartyName) {
                download(
                  result.data,
                  payload.creditPartyIdentifier + ".png",
                  content
                );
              } else {
                download(
                  result.data,
                  payload.creditPartyIdentifier +
                  "-" +
                  payload.creditPartyName +
                  ".png",
                  content
                );
              }
            }
          }
          setValidFile(false);
          reset();
          // reset({
          //   selectedMarket,
          //   trxCode: null,
          //   creditPartyIdentifier: null,
          //   creditPartyName: null,
          //   additionalQRParams: [],
          // });
          isLoading(false);
        })
        .catch((e) => {
          isLoading(false);
          if (e.response) {
            if (e.response.status === 403) {
              toast.error(UNAUTHORIZED_ACTION);
            } else if (e.response.status === 400) {
              toast.error(SOMETHING_WENT_WRONG);
            }
          } else {
            toast.error(SOMETHING_WENT_WRONG);
          }
        });
    } else {
      payload = jsonData;
      generateQRCodes(
        payload,
        selectedTemplate.id,
        downloadAsPDF,
        downloadAsQrIMG,
        qrImageSize,
        market
      )
        .then((result) => {
          if (result) {
            if (downloadAsPDF) {
              const content = result.headers["content-type"];
              download(result.data, "qr-codes.pdf", content);
            } else {
              const content = result.headers["content-type"];
              download(result.data, "qr-codes.zip", content);
            }
          }
          setValidFile(false);
          resetBulk();
          setFileNameText("");
          isLoading(false);
        })
        .catch((e) => {
          if (e.response && e.response.status && e.response.status === 403) {
            toast.error(UNAUTHORIZED_ACTION);
          } else if (e.response.data.Description) {
            toast.error(e.response.data);
          } else {
            toast.error(SOMETHING_WENT_WRONG);
          }
          isLoading(false);
        });
    }
  };

  const goBack = () => {
    history.goBack();
  };

  const buttonDisabled = () => {
    if (disableGenerateButton) {
      return true;
    } else {
      if (selectedOption === "single") {
        return !isValid || !isDirty || disableGenerateButton;
      } else {
        return !validFile || disableGenerateButton;
      }
    }
  };

  const handleFileUpload = (file) => {
    setFileNameText(file.name);
    csvToString(file)
      .then((result) => {
        const errors = validateCSV(
          result,
          validations,
          maxRecords,
          allRequiredFields,
          selectedTemplate.isDynamic
        );
        if (errors.length) {
          setFileNameText("");
          setCSVErrors(errors);
          setShowCSVErrorModal(true);
          setValidFile(false);
        } else {
          let jsonData = csvToJsonPapa(result);
          // map the fields to additionalData
          const mapped = jsonData.data.map((x) => {
            // remove first three keys
            const { a, b, c, ...params } = x;
            const additionalQRParams = Object.entries(params).map(
              ([key, value]) => ({
                mpaFieldName: key,
                mpaFieldValue: value,
              })
            );
            return {
              creditPartyIdentifier: x.creditPartyIdentifier,
              trxCode: x.trxCode,
              creditPartyName: x.creditPartyName,
              additionalQRParams,
            };
          });
          jsonData = addProceedingZero(mapped);
          setJsonData(jsonData);
          setValidFile(true);
        }
      })
      .catch((err) => {
        setValidFile(false);
      });
  };

  const downloadSampleCSV = () => {
    // get the header keys from the validations object
    const rawHeaderValues = additionalParams.map((x) => x.parentFieldKey);
    const mpaRequiredKeys = [
      "creditpartyname",
      "creditpartyidentifier",
      "trxcode",
    ];

    // remove the mpa required fields to reorder them
    const headerValues = rawHeaderValues.filter(
      (x) => !mpaRequiredKeys.includes(x.toLowerCase())
    );

    // add the mpa required fields to the begining of the csv
    headerValues.unshift("trxCode", "creditPartyIdentifier", "creditPartyName");

    // add any missing field from template 
    // additional fields added from template
    if (templateWithValidation.additionalFields) {
      templateWithValidation.additionalFields.forEach(x => {
        const index = headerValues.findIndex(k => k === x.mpaKey);
        if (index === -1) {
          // add the missing value
          headerValues.push(x.mpaKey);
        }
      });
    }

    const headers = [headerValues];
    const csvContent =
      "data:text/csv;charset=utf-8," +
      headers.map((e) => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sample-template.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
  };

  const onChangeOutput = (event) => {
    if (event.target.value === "image" && selectedOption === "bulk") {
      setMaxRecords(50);
    } else {
      setMaxRecords(200);
    }
    setSelectedOutput(event.target.value);
  };

  const onChangeOption = (event) => {
    if (event.target.value === "bulk" && selectedOutput === "image") {
      setMaxRecords(50);
    } else {
      setMaxRecords(200);
    }
    setSelectedOption(event.target.value);
  };

  const handleSizeSelection = async (ImageSize) => {
    try {
      qrImageSize = ImageSize;
      submitGenerateRequest(imageData, false, true);
    } catch (e) {
      if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.Description) {
        toast.error(e.response.data.Description);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
      showLoader(false);
    }
  };

  const toggleSetShowSizePicker = () => setShowSizePicker((p) => !p);

  return (
    <div className="template-page fill-parameter">
      <SelectQrImageSize
        show={showSizePicker}
        submitSize={handleSizeSelection}
        toggleShow={toggleSetShowSizePicker}
      />
      <Row className="add-padding">
        <Col lg="5" md="12" sm="12" className="left">
          <div className="page-title-row">
            <div className="title">
              <Button
                data-testid="go-back-btn"
                variant="primary"
                className="btn-link px-0 back-btn"
                id="back-button"
                onClick={goBack}
              >
                <MdKeyboardArrowLeft />
                Back
              </Button>
              <h1>{selectedTemplate.templateName}</h1>
              <div className="subtitle-1">
                You can choose to generate a QR Code at a time, or generate
                Multiple Codes using the Bulk Generate option (uploaded via a
                .csv file).
              </div>
            </div>
          </div>
          {showErrorMessage.show ? (
            <Alert variant="danger">{showErrorMessage.content}</Alert>
          ) : null}
          <Form className="form-2">
            <div className="pt-3 label-cust"> Select desired output</div>
            <div className="desired-output">
              <div className="form-check form-check-inline">
                <Form.Group>
                  <Form.Check
                    data-testid="pdf-radio-btn"
                    id="pdf-radio"
                    type="radio"
                    name="outputOptions"
                    value="pdf"
                    checked={selectedOutput === "pdf"}
                    label=" As a PDF with template(s)"
                    onChange={onChangeOutput}
                  />
                </Form.Group>
              </div>
              <div className="form-check form-check-inline">
                <Form.Group>
                  <Form.Check
                    data-testid="img-radio-btn"
                    id="image-radio"
                    type="radio"
                    name="outputOptions"
                    value="image"
                    checked={selectedOutput === "image"}
                    label=" As an image(s) with template"
                    onChange={onChangeOutput}
                  />
                </Form.Group>
              </div>
              <div
                className="form-check form-check-inline"
                data-testid="qr-img-radio-btn-div"
              >
                <Form.Group>
                  <Form.Check
                    data-testid="qr-img-radio-btn"
                    id="qr-image-radio"
                    type="radio"
                    name="outputOptions"
                    value="qrImage"
                    checked={selectedOutput === "qrImage"}
                    label=" As an image(s)"
                    onChange={onChangeOutput}
                  />
                </Form.Group>
              </div>
            </div>
            <hr />
            {permissions.hasIndividualPermission &&
              permissions.hasBulkPermission ? (
              <>
                <div className="pt-3 label-cust"> Select desired method</div>

                <div className="form-check form-check-inline">
                  <Form.Group>
                    <Form.Check
                      data-testid="gen-single-radio-btn"
                      id="single-radio"
                      type="radio"
                      name="inlineRadioOptions"
                      value="single"
                      checked={selectedOption === "single"}
                      label=" To Generate Single QR"
                      onChange={onChangeOption}
                    />
                  </Form.Group>
                </div>
                <div className="form-check form-check-inline">
                  <Form.Group>
                    <Form.Check
                      data-testid="gen-bulk-radio-btn"
                      id="bulk-radio"
                      type="radio"
                      name="inlineRadioOptions"
                      value="bulk"
                      checked={selectedOption === "bulk"}
                      label=" To Generate Bulk QR"
                      onChange={onChangeOption}
                    />
                  </Form.Group>
                </div>
              </>
            ) : null}
          </Form>
          {selectedOption === "single" ? (
            <Form
              className="form-2"
              id="uploadForm"
              onSubmit={handleSubmit(onSubmit)}
            >
              {isSuperAdmin && selectedTemplate.isGeneral ? (
                <div className="mt-5">
                  <Row>
                    <Col sm="4">
                      <Form.Label>Market *</Form.Label>
                    </Col>
                    <Col sm="8">
                      <div className="d-flex">
                        <div className="w-100">
                          <Form.Select
                            data-testid="market-dropdown"
                            aria-label="Select Market"
                            {...register("market")}
                            onChange={(e) =>
                              setAdditionalFieldsAndValidations(
                                templateWithValidation,
                                e.target.value
                              )
                            }
                            value={selectedMarket}
                          >
                            <option value="">Select Market</option>
                            {marketOptions.map((market) => {
                              return (
                                <option key={market.value} value={market.value}>
                                  {market.displayName}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <Form.Control.Feedback
                            id="market-dropdown-errors"
                            type="invalid"
                          >
                            {errors.market?.message}
                          </Form.Control.Feedback>
                        </div>
                        <span className="mt-1 mx-2">
                          <InfoToolTip content={"Market for the template"} />
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : null}

              {marketSelected ? (
                <span>
                  <div className="mt-5 bottom-space">
                    <Row>
                      <Col sm="4">
                        <Form.Label>Transaction Code*</Form.Label>
                      </Col>
                      <Col sm="8">
                        <div className="d-flex">
                          <div className="w-100">
                            <Form.Select
                              data-testid="transaction-code-dropdown"
                              {...register("trxCode", { required: true })}
                              aria-label="Select Transaction Code"
                              isInvalid={errors.trxCode}
                            >
                              <option value="">Select Transaction Code</option>
                              {trxTypeOptions.length > 0 &&
                                trxTypeOptions.map((trxType) => {
                                  return (
                                    <option
                                      key={trxType.value}
                                      value={trxType.value}
                                    >
                                      {trxType.label}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                            <Form.Control.Feedback
                              id="transaction-code-dropdown-errors"
                              type="invalid"
                            >
                              {errors.trxCode?.message}
                            </Form.Control.Feedback>
                          </div>
                          <span className="mt-1 mx-2">
                            <InfoToolTip content={helpText.trxCodeHelpText} />
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Form.Group
                    as={Row}
                    className="bottom-space"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4">
                      Credit Party Identifier*
                    </Form.Label>
                    <Col sm="8">
                      <div className="d-flex">
                        <div className="w-100">
                          <Form.Control
                            data-testid="cpi-text-field"
                            type="text"
                            {...register("creditPartyIdentifier", {
                              required: true,
                            })}
                            autoComplete="off"
                            isInvalid={errors.creditPartyIdentifier}
                            placeholder="Enter Credit Party Identifier"
                          />
                          <Form.Control.Feedback
                            data-testid="cpi-text-field-errors"
                            id="creditPartyIdentifierErrors"
                            type="invalid"
                          >
                            {errors.creditPartyIdentifier?.message}
                          </Form.Control.Feedback>
                        </div>
                        <span className="mt-1 mx-2">
                          <InfoToolTip content={helpText.cpiHelpText} />
                        </span>
                      </div>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="bottom-space"
                    controlId="creditPartyName"
                  >
                    <Form.Label column sm="4">
                      Credit Party Name*
                    </Form.Label>
                    <Col sm="8">
                      <div className="d-flex">
                        <div className="w-100">
                          <Form.Control
                            data-testid="cpn-text-field"
                            type="text"
                            autoComplete="off"
                            {...register("creditPartyName")}
                            placeholder="Enter Credit Party Name"
                            isInvalid={errors.creditPartyName}
                          />
                          <Form.Control.Feedback
                            data-testid="cpn-text-field-errors"
                            id="creditPartyNameErrors"
                            type="invalid"
                          >
                            {errors.creditPartyName?.message}
                          </Form.Control.Feedback>
                        </div>
                        <span className="m-auto mx-2">
                          <InfoToolTip content={helpText.cpnHelpText} />
                        </span>
                      </div>
                    </Col>
                  </Form.Group>

                  {qrParams.map((parameter, index) => {
                    return (
                      <Form.Group
                        as={Row}
                        className="bottom-space"
                        controlId="creditPartyName"
                        key={parameter.parentFieldKey}
                      >
                        <Form.Label column sm="4">
                          {parameter.fieldName}*
                        </Form.Label>
                        <Col sm="8">
                          <div className="d-flex">
                            <div className="w-100">
                              <Form.Control
                                data-testid={
                                  "additional-params-mapFieldLabel" + index
                                }
                                type="text"
                                hidden
                                {...register(
                                  `additionalQRParams.${index}.fieldLabel`
                                )}
                                value={parameter.fieldName}
                              />
                              <Form.Control
                                data-testid={
                                  "additional-params-mapFieldKey" + index
                                }
                                type="text"
                                hidden
                                {...register(
                                  `additionalQRParams.${index}.mpaFieldName`
                                )}
                                value={parameter.parentFieldKey}
                              />
                              <Form.Control
                                data-testid={"additional-params-format" + index}
                                type="text"
                                hidden
                                {...register(
                                  `additionalQRParams.${index}.format`
                                )}
                                value={parameter.validation.format}
                              />
                              <Form.Control
                                data-testid={
                                  "additional-params-lengthMin" + index
                                }
                                type="text"
                                hidden
                                {...register(
                                  `additionalQRParams.${index}.lengthMin`
                                )}
                                value={parameter.validation.lengthMin}
                              />
                              <Form.Control
                                data-testid={
                                  "additional-params-format-lengthMax" + index
                                }
                                type="text"
                                hidden
                                {...register(
                                  `additionalQRParams.${index}.lengthMax`
                                )}
                                value={parameter.validation.lengthMax}
                              />
                              <Form.Control
                                data-testid={
                                  "additional-params-format-lengthType" + index
                                }
                                type="text"
                                hidden
                                {...register(
                                  `additionalQRParams.${index}.lengthType`
                                )}
                                value={parameter.validation.lengthType}
                              />
                              <Form.Control
                                data-testid={
                                  "additional-params-mapFieldValue" + index
                                }
                                type="text"
                                {...register(
                                  `additionalQRParams.${index}.mpaFieldValue`
                                )}
                                autoComplete="off"
                                placeholder={"Enter " + parameter.fieldName}
                                isInvalid={
                                  errors.additionalQRParams?.[index]
                                    ?.mpaFieldValue
                                }
                              />
                              <Form.Control.Feedback
                                data-testid={
                                  "additional-params-mapFieldValue-error" +
                                  index
                                }
                                id={
                                  "additional-params-mapFieldValue-error" +
                                  index
                                }
                                type="invalid"
                              >
                                {
                                  errors.additionalQRParams?.[index]
                                    ?.mpaFieldValue.message
                                }
                              </Form.Control.Feedback>
                            </div>
                            <span className="m-auto mx-2">
                              <InfoToolTip content={parameter.fieldName} />
                            </span>
                          </div>
                        </Col>
                      </Form.Group>
                    );
                  })}
                </span>
              ) : null}
            </Form>
          ) : (
            <>
              <Form
                className="form-2 mt-3"
                id="uploadForm"
                onSubmit={handleSubmitBulk(onSubmit)}
              >
                {isSuperAdmin && selectedTemplate.isGeneral ? (
                  <div className="mt-5">
                    <hr></hr>
                    <Row>
                      <Col sm="4">
                        <Form.Label>Market *</Form.Label>
                      </Col>
                      <Col sm="8">
                        <div className="d-flex">
                          <div className="w-100">
                            <Form.Select
                              data-testid="market-dropdown"
                              aria-label="Select Market"
                              {...register("market")}
                              onChange={(e) =>
                                setAdditionalFieldsAndValidations(
                                  templateWithValidation,
                                  e.target.value
                                )
                              }
                              value={selectedMarket}
                            >
                              <option value="">Select Market</option>
                              {marketOptions.map((market) => {
                                return (
                                  <option
                                    key={market.value}
                                    value={market.value}
                                  >
                                    {market.displayName}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <Form.Control.Feedback
                              id="market-dropdown-errors"
                              type="invalid"
                            >
                              {errors.market?.message}
                            </Form.Control.Feedback>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <hr></hr>
                  </div>
                ) : null}
                <div className="d-lg-block csv-download">
                  <div className="wrap-csv-temp">
                    <span className="mx-1">
                      <BiDownload />
                    </span>

                    {isSuperAdmin &&
                      selectedTemplate.isGeneral &&
                      !marketSelected ? (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <Button variant="link">Download CSV Template</Button>
                      </OverlayTrigger>
                    ) : (
                      <Button
                        variant="link"
                        onClick={downloadSampleCSV}
                        disabled={
                          isSuperAdmin &&
                          selectedTemplate.isGeneral &&
                          !marketSelected
                        }
                      >
                        <ins>Download CSV Template</ins>
                      </Button>
                    )}
                  </div>
                </div>
                <div className="pt-4 label-cust">Select CSV File to upload</div>
                <div className="mr-3">
                  <FileUploader
                    data-testid="file-uploader"
                    disabled={
                      isSuperAdmin &&
                      selectedTemplate.isGeneral &&
                      !marketSelected
                    }
                    disabledMessage={"Select a market to upload a file"}
                    handleFile={handleFileUpload}
                    register={registerSchema}
                    errors={bulkErrors}
                    maxRecords={maxRecords}
                    fileNameText={fileNameText}
                  />
                </div>
              </Form>
              <hr />
              <div className="mt-3">
                <AiFillInfoCircle />
                <span className="help-text">
                  Use the guideline below for mapping the different transaction
                  codes, credit party identifiers and credit party names
                </span>
              </div>
              <Table className="mt-4 tbl-fill-para" bordered>
                <tr>
                  <th>TrxCode</th>
                  <th className="left-p">Transaction Type</th>
                  <th>Credit Party Identifier</th>
                  <th>Credit Party Name</th>
                </tr>
                <tr>
                  <td>01</td>
                  <td>Pay Merchant (Buy Goods)</td>
                  <td>Till number or shortcode</td>
                  <td>Organization name</td>
                </tr>
                <tr>
                  <td>02</td>
                  <td>Withdraw Cash at Agent Till</td>
                  <td>Agent Till or shortcode</td>
                  <td>Organization name</td>
                </tr>
                <tr>
                  <td>03</td>
                  <td>Pay Bill</td>
                  <td>Paybill (Business) number</td>
                  <td>Organization name</td>
                </tr>
                <tr>
                  <td>04</td>
                  <td>Send Money</td>
                  <td>Mobile number</td>
                  <td>Organization name</td>
                </tr>
                <tr>
                  <td>05</td>
                  <td>Send to Business</td>
                  <td>Business number (MSISDN format)</td>
                  <td>Organization name</td>
                </tr>
              </Table>
            </>
          )}
        </Col>
        <Col lg="7" md="12" sm="12" className="right">
          <div className="page-title-row">
            <div className="title mt-4">
              <h3>
                <span className="p-2">
                  <AiOutlineEye />
                </span>
                Template
              </h3>
            </div>
          </div>
          <Card className="preview mt-3">
            <div className="wrap-img">
              <Card.Img
                data-testid="template-img"
                variant="top"
                src={selectedTemplate.pngTemplatePath}
                alt="template1"
              />
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div className="footer-card">
            <div>
              <div className="frm-btn">
                <Button
                  data-testid="cancel-btn"
                  variant="primary"
                  className="btn-cancel mx-3"
                  onClick={goBack}
                  id="cancel-button"
                >
                  Cancel
                </Button>
                <Button
                  disabled={buttonDisabled()}
                  data-testid="generate-btn"
                  form="uploadForm"
                  variant="primary"
                  type="submit"
                  id="generate-pdf-button"
                >
                  Generate {selectedOutput === "pdf" ? "PDF" : "Image(s)"}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <CSVErrorsModal
        data-testid="csv-errors-modal"
        errors={csvErrors}
        changeExternalState={setShowCSVErrorModal}
        toggleExternal={showCSVErrorModal}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedTemplate: state.templates.current,
    currentUserMarket: state.profile.market,
    isSuperAdmin: state.permission.isSuperAdmin,
    additionalParams: state.qrSpecifiction.additionalParams,
    marketOptions: state.profile.markets,
    trxTypeOptions: state.transactionType.trxTypeOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  generateQRCodes: (data, templateId, isPDF, isQrImg, qrImageSize, market) =>
    dispatch(
      generateQRCodes(data, templateId, isPDF, isQrImg, qrImageSize, market)
    ),
  generateIndividualQRCode: (
    data,
    templateId,
    isPdf,
    isQrImg,
    qrImageSize,
    market
  ) =>
    dispatch(
      generateIndividualQRCode(
        data,
        templateId,
        isPdf,
        isQrImg,
        qrImageSize,
        market
      )
    ),
  isLoading: (flag) => dispatch(showLoader(flag)),
  getTemplate: (templateId) => dispatch(getTemplate(templateId)),
  checkPermissions: (permissions) => dispatch(checkPermission(permissions)),
  getQRFieldValidations: (market) => dispatch(getQRFieldValidations(market)),
  getLanguageList: (filters) => dispatch(getLanguages(filters)),
  getTransactionTypeList: (filters) => dispatch(getTransactionTypes(filters)),
  receiveMarketData: () => dispatch(receiveMarketData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FillParameters);
